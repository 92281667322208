export const clientNameFooter = "GRAZI BRILHO";
export const titleMain = "Seja Bem-Vindo!";
export const subtitleMain = "Conecte-se por meio da respiração!!";

export const aspectRatioLogoAbout = '1'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "GB TRAINER";
export const nomeFornecedor = "GB TRAINER";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/grazibrilhotrainer/",
    profile: "@grazibrilhotrainer"
  },
  {
    type: "material-community",
    name: "whatsapp",
    link: "",
    number: ""
  },
];

export const drawerClosedLinks = [
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = true;
export const drawerOpenedLinks = [];
